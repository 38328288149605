import { useEffect } from 'react';

import useGlobalContext from './useGlobalContext';

const useTitle = title => {
  const store = useGlobalContext();

  useEffect(() => {
    store.title = title;

    return () => {
      store.title = '';
    };
  }, [store, title]);
};

export default useTitle;
