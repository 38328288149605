import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import dynamic from 'next/dynamic';

import { MIN_PASSWORD_LENGTH } from 'utils/constants';

const ExternalPasswordStrengthBar = dynamic(() => import('react-password-strength-bar'));

const withAriaLive = word => <span aria-live="polite">{word}</span>;
const defaultScoreWords = ['weak', 'weak', 'okay', 'good', 'strong'];

const useStyles = makeStyles({
  bar: {
    '& > div': {
      '& > div': {
        height: '4px !important',
        borderRadius: 3,
      },
    },
  },
});

const PasswordStrengthBar = ({ userInputs, password }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <ExternalPasswordStrengthBar
      password={password}
      minLength={MIN_PASSWORD_LENGTH}
      userInputs={userInputs.concat(['metlife', 'password', 'legalplans'])}
      scoreWordStyle={{ color: theme.palette.text.primary }}
      scoreWords={defaultScoreWords.map(withAriaLive)}
      shortScoreWord={withAriaLive('too short')}
      barColors={[theme.palette.grey[600], '#DA0000', '#6600FF', '#007DB8', '#01892A']}
      className={classes.bar}
    />
  );
};

PasswordStrengthBar.propTypes = {
  userInputs: PropTypes.array,
  password: PropTypes.string,
};

PasswordStrengthBar.defaultProps = {
  userInputs: [],
  password: '',
};

export default observer(PasswordStrengthBar);
