import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';

import { useGlobalContext } from 'hooks';
import Register from 'pages/register';
import IdleSessionHandler from '../zg_utils/IdleSessionHandler';

const ProtectedPage = ({ children }) => {
  const { authenticated, loading } = useGlobalContext();
  const router = useRouter();

  useEffect(() => {
    if (authenticated || loading || !router.isReady) return;

    const shouldRedirectToLogin = router.query.showLogin === 'true';

    router.replace({
      pathname: shouldRedirectToLogin ? '/login' : '/register',
      shallow: true,
      query: { localNext: router.asPath, next: router.query.next },
    });
  }, [authenticated, loading, router]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authenticated) {
    return <Register />;
  }

  return (
    <>
      <IdleSessionHandler
        expirationHandler={() => {
          router.push('/api/logout/');
        }}
      />
      {children}
    </>
  );
};

ProtectedPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(ProtectedPage);
