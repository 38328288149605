import { observer } from 'mobx-react';

import Layout from 'components/Layout';
import RegisterForm from 'components/Register';

import { useHiddenNav, useTitle } from 'hooks';

const Register = () => {
  useHiddenNav();
  useTitle('Register');

  return (
    <Layout>
      <RegisterForm />
    </Layout>
  );
};

Register.public = true;

export default observer(Register);
