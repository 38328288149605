// eslint-disable-next-line import/prefer-default-export
export const isLocalUrl = url => {
  if (!URL.canParse(url, document.baseURI)) {
    return false;
  }
  const urlObj = new URL(url, document.baseURI);
  const isLocal = urlObj.origin === new URL(url, document.baseURI).origin;
  const isTrivial = urlObj.pathname === '/';
  return isLocal && !isTrivial;
};
