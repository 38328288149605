import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Panel from '@wui/layout/panel';

const Layout = ({ showLogo, children, slim }) => {
  const router = useRouter();
  const [showWelcome, setShowWelcome] = useState(false);
  const [showLegacySponsorWelcome, setShowLegacySponsorWelcome] = useState(false);

  useEffect(() => {
    // upwise is a MetLife mobile application
    if (router.asPath.includes('upwise')) {
      setShowWelcome(true);
    }
    if (router.asPath.includes('fromLegacySponsorApp')) {
      setShowLegacySponsorWelcome(true);
    }
  }, [router.asPath]);

  return (
    <div>
      <Grid container direction="column" alignItems="center" wrap="nowrap">
        <Spacer v={32} />
        <Spacer v={60} xsDown />
        {showLogo && (
          <>
            <Link href="/">
              <a>
                <img src="/images/mlp-logo.svg" alt="MetLife Logo home page" />
              </a>
            </Link>
            {showWelcome && (
              <Container maxWidth="sm">
                <Spacer v={32} />
                <Typography variant="h6" component="p">
                  Welcome! You are about to start a simple, self-guided process to complete your
                  estate plan. This can take as little as 15 minutes.
                </Typography>
                <Spacer v={12} />
                <Typography variant="h6" component="p">
                  Let’s get started by creating a secure MetLife Legal Plans account.
                </Typography>
              </Container>
            )}
            {showLegacySponsorWelcome && (
              <Container maxWidth="sm">
                <Spacer v={32} />
                <Typography variant="h6" component="p">
                  Welcome to MetLife Legal Plans' Administrative Website. This site is intended to
                  support our customers with plan administration and enrollment.
                </Typography>
                <Spacer v={12} />
                <Typography variant="h6" component="p">
                  We’ve upgraded the web experience. If you already have an account, please use your
                  email and existing password to get started.
                </Typography>
              </Container>
            )}
          </>
        )}
        <Spacer v={32} />
        <Panel lessPadding={slim}>{children}</Panel>
        <Spacer v={92} />
      </Grid>
    </div>
  );
};

Layout.propTypes = {
  showLogo: PropTypes.bool,
  children: PropTypes.node.isRequired,
  slim: PropTypes.bool,
};

Layout.defaultProps = {
  showLogo: true,
  slim: false,
};

export default observer(Layout);
