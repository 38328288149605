import Head from 'next/head';

import { observer } from 'mobx-react';
import { useGlobalContext } from 'hooks';

const TitleWatcher = () => {
  const { title } = useGlobalContext();

  return (
    <Head>
      <title>{title ? `${title} | ` : ''} MetLife Legal Plans</title>
    </Head>
  );
};

export default observer(TitleWatcher);
