import { getCurrentUserSettings } from 'utils/API';

import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { SUPPORT_PHONE_NUMBER } from 'utils/constants/index';

const initialData = { data: { supportPhoneNumber: SUPPORT_PHONE_NUMBER } };

/*
  1. set staleTime to Infinity to prevent the query from being executed AFTER the first time it goes to the backend to get data
  2. initialData will pre-fill the cache with said data
  3. enabled indicates whether the query should run or not
*/

// the goal here is to pre-fill the cache with initial data when the hook is used on a public page but the first time it's used on a private page, we go to the server to fetch the support phone number ONCE, cache the data, and never hit the server again
export default function useCurrentUserQuery(override = {}) {
  const router = useRouter();

  const isComponentPublic = router.components?.[router.pathname]?.Component?.public ?? false;
  return useQuery(['current-user-settings'], getCurrentUserSettings, {
    ...override,
    staleTime: Infinity,
    initialData: isComponentPublic ? initialData : undefined,
    enabled: !isComponentPublic,
    select: data => ({
      ...data.data,
    }),
  });
}
